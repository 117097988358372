import fifteen1 from '../assets/certificates/ISO_9001_2015_May 2021 to May 2024.pdf'
import fifteen2 from '../assets/certificates/ISO_9001_2015_May 2018 to May 2021.pdf'
import fifteen3 from '../assets/certificates/ISO_14001_2015_August 2020 to August 2021.pdf'
import fifteen4 from '../assets/certificates/ISO_14001_2015_August 2021 to August 2022.pdf'
import fifteen5 from '../assets/certificates/ISO_14001_2015_May 2023 to May 2026.pdf'
import fifteen6 from '../assets/certificates/ISO 9001_2015_May 2024 to May 2027.pdf'

import sixteen1 from '../assets/certificates/ISO_13485_2016_January 2023 to January 2026.pdf'
import cmmi_l4_1 from '../assets/certificates/CMMI_L-4_January 2023 to January 2026.pdf'
import thirteen1 from '../assets/certificates/ISO_27001_2013_August 2023 to October 2025.pdf'

import eight1 from '../assets/certificates/ISO_9001_2008_May 2015 to May 2018.pdf'
import eight2 from '../assets/certificates/ISO_9001_2008_May 2012 to May 2015.pdf'  
import eight3 from '../assets/certificates/ISO_9001_2008_May 2009 to May 2012.pdf' 
// import eight3 from '../assets/certificates/ISO_9001:2008.pdf' 


//CASE STUDIES realtime
import case1 from '../assets/blog_n_case/Data acquisition and remote monitoring for Railways.pdf'
import case2 from '../assets/blog_n_case/_ESP32 based controller for remote reading of power status  (3).pdf'
import case3 from '../assets/blog_n_case/ESP32 based advanced data acquisition system.pdf'
import case4 from '../assets/blog_n_case/ESP32 based wireless temperature probe for Industrial use case .pdf'
import case5 from '../assets/blog_n_case/Remote-monitoring-system_case-study.pdf'



export const research = [
    {
        images: "./images/homepage-icons/Research/Research.png",
        text: "research"
    },
    {
        images: "./images/homepage-icons/Research/Design.jpg",
        text: "Design"
    },
    {
        images: "./images/homepage-icons/Research/Development.jpg",
        text: "Development"
    },
    // {
    //     images: "./images/homepage-icons/Research/research-03.jpg",
    //     text: "Prototype Testing"
    // },
    {
        // images: "./images/homepage-icons/Research/research-04.jpg",
        images: "./images/homepage-icons/Research/Manufaturing.jpg",
        text: "manufacturing"
    },
]


export const testingAnalysers = [
    {
        images: "./images/hardwaretesttools.png",
        // text: "research"
    },
    {
        images: "./images/research-03.png",
        // text: "Design "
    },
    {
        images: "./images/processed_2.png",
        // text: "Development"
    },
    // {
    //     images: "./images/research-03.jpg",
    //     text: "Prototype Testing"
    // },
    {
        // images: "./images/research-04.jpg",
        images: "./images/reserch-manufacturing.png",
        text: "manufacturing"
    },
]




export const reshape = [
    {
        images: "./images/man-worker-firld-by-solar-panels.png",
        text1: "SETTING UP BENCHMARKS IN CLEAN ENERGY TRANSITION",
        text2: "Developed a range of BIS-approved end-to-end smart solar and wind solutions helping expedite clean energy transition across commercial and residential segments.",
        text3: "Market leaders in multiple product domains",
    },
    {
        images: "./images/reshape-SLS1.png",
        text1: "TURNKEY SOLUTIONS PROVIDER FOR EV CHARGING INDUSTRY",
        text2: "Offering the most innovative range of EV Charging solutions to help businesses scale, boost operational efficiency, and maximize ROI.",
        text3: "Helping switch to smarter means of mobility",
    },
    {
        // images: "./images/reshape-thirdbnner.png",
        images: "./images/homepage-icons/iot.png",
        text1: "REDEFINING INTEROPERABILITY WITH END-TO-END IOT PLATFORM",
        text2: "A heterogeneous IoT platform providing multi-protocol support for services including G3-PLC, WiFi, BLE, Sigfox, LoRa, GSM, etc.",
        text3: "Stack of feature-rich SDKs for supporting IoT deployments",
    },
    {
        images: "./images/reshape-SLS3.png",
        text1: "SPEARHEADING INNOVATION IN THE CLOUD ECOSYSTEM",
        // text2: "Business-agnostic cloud solut     ions already catered to more than ___ businesses from ____ industries.",
        text2: "Business-agnostic cloud solutions for the agile businesses of tomorrow.",
        text3: "Reinventing how businesses expand the reach of their influence",
        // br: 'br',
        // br1:'br1'
    },

]


export const port = [
    {
        images: './images/homepage-icons/32._IP Core-FPGA.svg',
        text: 'ASIC/ FPGA/ IP Core',
        link: 'ipcore'
    },
    {
        images: './images/homepage-icons/1._IOT_Platform.svg',
        text: 'IoT Platform',
        link: 'iot'
    },
    {
        images: './images/homepage-icons/2._Cloud_Solution.svg',
        text: 'Cloud Solutions',
        link: 'cloud'
    },
    {
        images: './images/homepage-icons/3._E-Mobility.svg',
        text: 'EV Charging Solutions',
        link: 'evcharger'
    },
    {
        images: './images/homepage-icons/4._Solar_&_Wind.svg',
        text: 'Solar & Wind',
        link: 'solar'
    },
    {
        images: './images/homepage-icons/5._Smart_Metering.svg',
        text: 'Smart Metering',
        link: 'smartmeter'
    },
    {
        images: './images/homepage-icons/7._Home_Automation.svg',
        text: 'Home Automation',
        link: 'homeauto'
    },
    {
        images: './images/homepage-icons/8._Smart_Street_Light.svg',
        text: 'Smart Street Lighting',
        link: 'smartstreet'
    },
    {
        images: './images/homepage-icons/9._Railways.svg',
        text: 'Railways',
        link: 'railways'
    },
    {
        images: './images/homepage-icons/12._Digital Signage.svg',
        text: 'Digital Signage',
        link: 'digital'
    },
    {
        images: './images/homepage-icons/11._Traffic_Solutions.svg',
        text: 'Smart Traffic Solutions',
        link: 'smarttraffic'
    },
    {
        images: './images/homepage-icons/10._Gaming.svg',
        text: 'Smart Gaming',
        link: 'gaming',
    },
]




export const realtime = [
    {
        images: './images/homepage-icons/blogs/Second.png',
        case: 'BLOG',
        head: 'Memory Protection Unit',
        read: 'https://core.slscorp.com/resources/blogs/160-fpga/281-memory-protection-unit.html'
    },


    {
        images: './images/homepage-icons/blogs/case_NLG6700.png',
        case: 'CASE STUDY',
        head: 'Robust & scalable system for data acquisition & remote monitoring for railways.',
        read: `${case1}`
    },


    {
        images: './images/homepage-icons/blogs/third.png',
        case: 'BLOG',
        head: 'Does throughput matter in USB?',
        // para: 'Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        read: 'https://core.slscorp.com/resources/blogs/160-fpga/279-does-throughput-matters-in-usb.html'
    },


    {
        images: './images/homepage-icons/blogs/case_NWK2RLY.png',
        case: 'CASE STUDY',
        head: 'Remote Monitoring System for automatic control of pumps in effluent treatment plants.',
        read: `${case5}`
    },



    {
        images: './images/homepage-icons/blogs/fourth.jpg',
        case: 'BLOG',
        head: 'What differentiates FPGA from ASIC?',
        // para: 'Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        read: 'https://core.slscorp.com/resources/blogs/160-fpga/277-what-differentiates-fpga-from-asic.html'
    },


    {
        images: './images/homepage-icons/blogs/case_NLC1200.png',
        case: 'CASE STUDY',
        head: 'Product for reading & monitoring ON/OFF status of devices operating at different voltages.',
        read: `${case2}`
    },


    {
        images: './images/homepage-icons/blogs/fifth.png',
        case: 'BLOG',
        head: 'What is FPGA and why is it required?',
        // para: 'Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        read: 'https://core.slscorp.com/resources/blogs/160-fpga/276-what-is-fpga-and-why-is-it-required.html'
    },


    {
        images: './images/homepage-icons/blogs/case_NLC1242.png',
        case: 'CASE STUDY',
        head: 'An industry standard data acquisition system for wireless automation.',
        read: `${case3}`
    },

    // {
    //     images: './images/homepage-icons/blogs/Second.png',
    //     case: 'BLOG',
    //     head: 'Memory Protection Unit',
    //     read: 'https://core.slscorp.com/resources/blogs/160-fpga/281-memory-protection-unit.html'
    // },
    {
        images: './images/homepage-icons/blogs/case_NLC1110.png',
        case: 'CASE STUDY',
        head: 'Device for sensing accurate temperature values from 0℃ to 1000℃.',
        read: `${case4}`
    },


]



export const certification = [
    // {
    //     logo: './images/about/certificatesLogo/Logo4.png',
    //     head: 'ISO 9001:2008',
    //     para: '(May 2009 to May 2012)',
    //     href: `${eight3}`,
    //     read: `${eight3}`,

    // },
    // {
    //     logo: './images/about/certificatesLogo/Logo6.png',
    //     head: 'ISO 9001:2008',
    //     para: '(May 2015 to May 2018)',
    //     href: `${eight1}`,
    //     read: `${eight1}`,

    // },
    {
        logo: './images/about/certificatesLogo/Logo2.png',
        head: 'ISO 9001:2015',
        para: '(May 2024 to May 2027)',
        href: `${fifteen6}`,
        read: `${fifteen6}`,
    },
    // {
    //     logo: './images/about/certificatesLogo/Logo1.png',
    //     head: 'ISO 14001:2015',
    //     para: '(August 2021 to August 2022)',
    //     read: `${fifteen4}`,
    //     href: `${fifteen4}`,

    // },
    // {
    //     logo: './images/about/certificatesLogo/Logo3.png',
    //     head: 'ISO 9001:2015',
    //     para: '(May 2018 to May 2021)',
    //     href: `${fifteen2}`,
    //     read: `${fifteen2}`,

    // },
    // {
    //     logo: './images/about/certificatesLogo/Logo5.png',
    //     head: '  ISO 9001:2008 ',
    //     para: ' (May 2012 to May 2015)',
    //     href: `${eight2}`,
    //     read: `${eight2}`,

    // },
    // {
    //     logo: './images/about/certificatesLogo/Logo1.png',
    //     head: 'ISO 14001:2015',
    //     para: '(August 2020 to August 2021)',
    //     read: `${fifteen3}`,
    //     read: `${fifteen3}`,

    // },
    {
        logo: './images/about/certificatesLogo/acme.jpg',
        head: 'ISO 14001:2015',
        para: '(May 2023 to May 2026)',
        read: `${fifteen5}`,
        read: `${fifteen5}`,

    },
    {
        logo: './images/about/certificatesLogo/uk_cert_and_ukaf.png',
        head: 'ISO 13485:2016',
        para: '(January 2023 to January 2026)',
        read: `${sixteen1}`,
        read: `${sixteen1}`,

    },
    {
        logo: './images/about/certificatesLogo/uk_cert_and_ukaf.png',
        head: 'CMMI L4',
        para: '(January 2023 to January 2026)',
        read: `${cmmi_l4_1}`,
        read: `${cmmi_l4_1}`,

    },
    {
        logo: './images/about/certificatesLogo/ISO_27001_2013_UKAS.jpg',
        head: 'ISO 27001:2013',
        para: '(August 2023 to October 2025)',
        read: `${thirteen1}`,
        read: `${thirteen1}`,

    },

]

export const csrcarousel = [
    {
        icon:'./images/about/csr-icons/95._Traffic_Infrastructure .svg',
        images: './images/about/csr/csr1.png',
        para: "As part of our mission to develop Anand from the core, we have installed free-of-cost traffic signages across the city to uplift the traffic infrastructure and contribute to Gujarat's Smart City initiative.",
    },
    {
        icon:'./images/about/csr-icons/96._NGOs.svg',
        images: './images/about/csr/csr5.jpeg',
        para: 'SLS has partnered with various NGOs to help uplift India from within India. Every year, the entire team donates necessities to orphans, handicapped, and old age people to help them lead a normal life.',
    },


    {
        icon:'./images/about/csr-icons/97._Blood_Donation_Camps.svg',
        images: './images/about/csr/csr2.png',
        para: 'SLS continually organizes and participates in Blood Donation Camps where the entire SLS family donates blood for free to help the economically weaker sections of the society.',
    },


    {
        icon:'./images/about/csr-icons/98._Tree_Plantation_Drives.svg',
        images: './images/about/csr/csr4.jpeg',
        para: "The entire SLS team organizes and participates in tree plantation drives across the year. Under this initiative, we have planted and are maintaining more than 500 trees throughout our campus in Anand.",
    },

]
export const expandingData = [
    {
        images: './images/homepage-icons/expanding/FPGA1.png',
        head: "IP CORE/ASIC/FPGA/SoC Design Services",
        para: "Ready-to-deploy FPGA solutions designed to meet dynamic market needs & keep up with the changing status quo.",
    },
    {
        images: './images/homepage-icons/expanding/PCB.png',
        head: "High Speed PCB Design Services",
        para: ' Use case-specific high-speed, multi-layered, multi-CPU PCB Board Designs to help companies cut down the deployment time.',
    },
    {
        images: './images/homepage-icons/expanding/Software-Development.png',
        head: "Software Development",
        para: ' Industry leaders in turnkey software solutions. Expertise in Embedded, Android, iOS, Web Applications, and windows-based software development.',
    },
    {
        images: './images/homepage-icons/expanding/Electronics-manufacturing.png',
        head: "Electronics Manufacturing Services (EMS)",
        para: " Turnkey EMS provider helping businesses with PCB Assembly, Prototyping, Small & Medium Volume Production.",
    },
    {
        images: './images/homepage-icons/expanding/Testing-calibration.png',
        head: "Testing and Calibration",
        para: " Easing quality checks, compliances, and certifications, we help companies build and deploy quality-tested products.",
    },
    {
        images: './images/homepage-icons/expanding/ML-AI.png',
        head: "Machine Learning & Artificial Intelligence",
        para: " Catering to the healthcare sector through new-age intelligence, offering innovative solutions to ease diagnosis.",
    },

]


export const iotnebulink = [
    {
        images: './images/iot/NebuLink-Node.png',
        subtitle: 'NebuLink Node',
        link: "https://www.nebulae.io/Home/Node"
    },
    {
        images: './images/iot/NebuLink-Gateway.jpg',
        subtitle: 'NebuLink Gateway',
        link: "https://www.nebulae.io/Home/Gateway"
    },
    {
        images: './images/iot/nebulink-controller.png',
        subtitle: 'NebuLink Controller',
        link: ""
    },
]

export const evchargers = [
    {
        images: './images/evcharger/evchargers/Home-Charger.png',
        subtitle: 'Home Charger',
    },
    {
        images: './images/evcharger/evchargers/Bharat-AC-001-Charger.png',
        subtitle: 'Bharat AC-001 Charger',
    },
    {
        images: './images/evcharger/evchargers/Type-2-AC-Dual-gun-vertical-charger.png',
        subtitle: 'Type 2 AC Dual Gun Vertical Charger',
    },
    {
        images: './images/evcharger/evchargers/Type-2-AC-Dual-gun-wall-mounted-charger.png',
        subtitle: 'Type 2 AC Dual Gun Wall-Mounted Charger',
    },
    {
        images: './images/evcharger/evchargers/Hybrid-AC-charger-with-3P-socket.png',
        subtitle: 'Hybrid AC Charger with 3P Socket',
    },
    {
        images: './images/evcharger/evchargers/Hybrid-AC-charger-with-IEC-Socket.png',
        subtitle: 'Hybrid AC Charger with IEC Socket',
    },
    {
        images: './images/evcharger/evchargers/Type-2-AC-Single-Gun-Charger.png',
        subtitle: 'Type 2 AC Single Gun Charger',
    },
]

//just code in case !!!
export const evchargersOld = [
    {
        images: './images/evcharger/evchargers/C2.png',
        subtitle: 'Home Charger',
    },
    {
        images: './images/evcharger/evchargers/C3.png',
        subtitle: 'Bharat AC-001 Charger',
    },
    {
        images: './images/evcharger/evchargers/C4.jpg',
        subtitle: 'Type 2 AC Dual Gun Vertical Charger',
    },
    {
        images: './images/evcharger/evchargers/C6.jpg',
        subtitle: 'Type 2 AC Dual Gun Wall-Mounted Charger',
    },
    {
        images: './images/evcharger/evchargers/C1.jpg',
        subtitle: 'Hybrid AC Charger with 3P Socket',
    },
    {
        images: './images/evcharger/evchargers/C5.jpg',
        subtitle: 'Hybrid AC Charger with IEC Socket',
    },
    {
        images: './images/evcharger/evchargers/C7.jpg',
        subtitle: 'Type 2 AC Single Gun Charger',
    },
]

export const iotOverview = [
    {
        images: './images/iot/iotOverview/57._Ready to use device-SDKs for faster time-to-market.svg',
        text: 'Ready-To-Use SDKs'
    },
    {
        images: './images/iot/iotOverview/58._Secured cloud platforms for Data Analytics & Real-time Monitoring.svg',
        text: 'Secured Cloud Platform',
    },
    {
        images: './images/iot/iotOverview/59._Scalability purpose-built for smart & widespread business applications.svg',
        text: 'Scalable Infrastructure'
    },

]


export const evchargercontrollers = [
    {
        images: './images/evcharger/lite.png',
        subtitle: 'Verycon Lite',
    },
    {
        images: './images/evcharger/1.0.png',
        subtitle: 'Verycon 1.0',
    },
    {
        images: './images/evcharger/Verycon2_0.png',
        subtitle: 'Verycon 2.0',
    },
    {
        images: './images/evcharger/verycon_new_pro.png',
        subtitle: 'Verycon Pro',
    },
    // {
    //     images: './images/ocpp-controller_350x350.png',
    //     subtitle: 'OCPP Controller',
    // },
]

export const middleware = [
    {
        images: './images/Nebulae_Website Images-05.png',
        subtitle: 'IoT Middleware',
        link: 'https://www.nebulae.io/Home/Middleware'
    },
    {
        images: './images/Nebulae_Website Images-06.png',
        subtitle: 'LoRaWAN Server',
        link: 'https://www.nebulae.io/Home/LoRaWAN'
    },
    {
        images: './images/Nebulae_Website Images-07.png',
        subtitle: 'FOTA Services',
        link: 'https://www.nebulae.io/Home/FOTA'
    },
    {
        images: './images/Nebulae_Website Images-08.png',
        subtitle: 'Analytics',
        link: 'https://www.nebulae.io/Home/Analytics'
    },
]

export const yalgar = [
    {
        images: './images/cloud/yalgaar/21._Chat and Social Media Sites.svg',
        text: 'Chat and Social Media Sites'
    },
    {
        images: './images/cloud/yalgaar/22._IoT and M2M Connected Devices.svg',
        text: 'IoT and M2M Connected Devices'
    },
    {
        images: './images/cloud/yalgaar/23._Real-time Tracking.svg',
        text: 'Real-Time Tracking'
    },
    {
        images: './images/cloud/yalgaar/24._AR-VR Multiplayer Games.svg',
        text: 'AR/VR Multiplayer Games'
    },
    {
        images: './images/cloud/yalgaar/25._Real-time Dashboards.svg',
        text: 'Real-Time Dashboards'
    },
    {
        images: './images/cloud/yalgaar/26._Financial Data Streaming.svg',
        text: 'Financial Data Streaming'
    },
]
export const transSmartFirm = [
    {
        images: './images/transformers/iconsOverview/47._Handles arithmetic and logical operations.svg',
        text: 'Handles Arithmetic And Logical Operations'
    },
    {
        images: './images/transformers/iconsOverview/48._Data collection at unit levels.svg',
        text: 'Data Collection At Unit Levels'
    },
    {
        images: './images/transformers/iconsOverview/49._Monitors communication with the cloud.svg',
        text: 'Monitors Communication With The Cloud'
    },
    {
        images: './images/transformers/iconsOverview/50._Communication with DTMS mobile app.svg',
        text: 'Communication With DTMS Mobile App'
    },
]


export const loc = [
    {
        images: './images/cloud/loc/17._Low Battery Consumption.svg',
        text: 'Low Battery Consumption'
    },
    {
        images: './images/cloud/loc/18._RF Independent Solution.svg',
        text: 'RF Independent Solution'
    },
    {
        images: './images/cloud/loc/19._Hardware Agnostic.svg',
        text: 'Hardware Agnostic'
    },
    {
        images: './images/cloud/loc/20._APIs for third party Integrations.svg',
        text: 'APIs For Third Party Integration'
    },
]

//White icons only 4 for DigitalTemplate
export const digital = [
    {
        images: './images/digital-signage/28._Manage your Content.svg',
        text: 'Manage Your Content'
    },
    {
        images: './images/digital-signage/29._Diagnose Remotely.svg',
        text: 'Remote Diagnose'
    },
    {
        images: './images/digital-signage/30._Monitor Proof of Play.svg',
        text: 'Monitor Proof Of Play'
    },
    {
        images: './images/digital-signage/31._Easy Authentication.svg',
        text: 'Easy Authentication'
    },
]


//White icons only 4 for DigitalTemplate
export const cloudOverview = [
    // {
    //     images: './images/cloud/cloudOverview/73._APIs for real-time tracking.svg',
    //     text: 'APIs For Real-time Tracking'
    // },
    {
        images: './images/cloud/cloudOverview/73._APIs-SDK for real-time tracking.svg',
        text: 'APIs-SDK For Real-Time Tracking'
    },
    {
        images: './images/cloud/cloudOverview/74._Easy to integrate.svg',
        text: 'Easy-To-Integrate'
    },
    {
        images: './images/cloud/cloudOverview/75._Scalable Solutions.svg',
        text: 'Scalable Solutions'
    },
    {
        images: './images/cloud/cloudOverview/76._Developer-friendly features.svg',
        text: 'Developer-Friendly Features'
    },
]

//White icons only 4 for SmartstreetTemplate
export const smartstreet = [
    {
        images: './images/smartstreet/14._Retrofit for existing infrastructure-(RF and LoRa variants).svg',
        text: 'Retrofit For Existing Infrastructure'
    },
    {
        images: './images/smartstreet/13._Energy efficient with motion-& ambient light sensors.svg',
        text: 'Energy Efficient With Different Sensors'
    },
    {
        images: './images/smartstreet/15._Real-time alerts-on malfunctions.svg',
        text: 'Real-Time Alerts On Malfunctions'
    },
    {
        images: './images/smartstreet/16._Real-time reports for lighting status,-performance, energy consumption, etc.svg',
        text: 'Detailed Reports Shown In Real-Time'
    },
]
export const railwayOverview = [
    {
        images: './images/railways/Fault Monitoring.svg',
        text: 'Fault Monitoring'
    },
    {
        images: './images/railways/Remote Monitoring.svg',
        text: 'Remote Monitoring'
    },
    {
        images: './images/railways/Reporting Live & Historical Data.svg',
        text: 'Reporting Live & Historical Data'
    },
    {
        images: './images/railways/Train Backbone Communication.svg',
        text: 'Train Backbone Communication'
    },
]
//White icons only 4 for SmarttrafficTemplate
export const smarttraffic = [
    {
        // images: './images/Layer_27-smarttraffic02.svg',
        images: './images/smarttraffic/LED-embedded-warning-signs.jpg',
        text: 'LED Embedded Warning Signs'
    },
    {
        // images: './images/Layer_35-smarttraffic03.svg',
        images: './images/smarttraffic/School-Zone-warning.jpg',
        text: 'School Zone Warning'
    },
    {
        // images: './images/Layer_36-smarttraffic04.svg',
        images: './images/smarttraffic/Pedestrain-crosswalk-solution.jpg',
        text: 'Pedestrian Crosswalk Solutions'
    },
    {
        // images: './images/Layer_37-smarttraffic05.svg',
        images: './images/smarttraffic/Dynamic-curve-warning.jpg',
        text: 'Dynamic Curve Warning'
    },
    {
        // images: './images/Layer_38-smarttraffic06.svg',
        images: './images/smarttraffic/Wrong-way-detection.png',
        text: 'Wrong-Way Detection'
    },
    {
        // images: './images/Layer_39-smarttraffic07.svg',
        images: './images/smarttraffic/Variable-message-signs.jpg',
        text: 'Variable Message Signs'
    },
]
export const ipcoreBoxes = [
    {
        // images: './images/Layer_27-smarttraffic02.svg',
        images: "./images/FPGA_Website_Image (5).png",
        text: 'IP Core Development & Customization'
    },
    {
        // images: './images/Layer_35-smarttraffic03.svg',
        images: './images/FPGA_Website_Image (1).png',
        text: "FPGA Turnkey Solutions"
    },
    {
        // images: './images/Layer_36-smarttraffic04.svg',
        images: './images/FPGA_Website_Image (4).png',
        // text: "IP CORE-to-FPGA Solutions"
        text: "ASIC-to-FPGA Solutions"
    },
    {
        // images: './images/Layer_37-smarttraffic05.svg',
        images: './images/FPGA_Website_Image (3).png',
        text: "Product Customization"
    },
    {
        // images: './images/Layer_38-smarttraffic06.svg',
        images: './images/FPGA_Website_Image (2).png',
        text: 'Driver Development'
    },
]
export const smarttrafficCards = [
    {
        images: './images/slider2-smarttraffic10.png',
        texthead: 'Controller',
        text: 'Our smart traffic management controller retrofits with the existing traffic infrastructure and transforms it into a smarter, more interactive traffic solution.'
    },
    {
        images: './images/about-imac-smarttraffic01.png',
        texthead: 'Traffic Management Dashboard',
        text: 'Our Traffic Management Dashboard is a cloud-based intuitive dashboard that allows you to remotly control and monitor your intelligent traffic infrastructure.'
    },

]



//White icons only 4 for SolarTemplate
export const solar = [
    {
        images: './images/solar/icons/36._End-to-end Solar and Wind Solutions.svg',
        text: 'End-To-End Solar & Wind Solutions'
    },
    {
        images: './images/solar/icons/37._Powered through IoT.svg',
        text: 'Powered Through IoT'
    },
    {
        images: './images/solar/icons/38._Real-time alerts on-abnormality detection.svg',
        text: 'Real-Time Abnormality Detection'
    },
    {
        images: './images/solar/icons/39._Real-time reports of panel-wise energy generation.svg',
        text: 'Real-Time Panel-Wise Energy Reports'
    },
    // {
    //     images: './images/solar/icons/40._Predictive energy optimization.svg',
    //     text: 'Predictive energy optimization'
    // },
]

export const homeAutoOverview = [
    // {
    //     images: './images/homeautomation/overview/87._Temperature Sensor.svg',
    //     text: 'Temperature Sensor'
    // },
    // {
    //     images: './images/homeautomation/overview/88._Humidity Sensor.svg',
    //     text: 'Humidity Sensor'
    // },
    // {
    //     images: './images/homeautomation/overview/89._Motion Sensor.svg',
    //     text: 'Motion Sensor'
    // },
    // {
    //     images: './images/homeautomation/overview/90._Smoke Sensor.svg',
    //     text: 'Smoke Sensor'
    // },
    {
        images: './images/homeautomation/overview/91._Konnsys_App_Support.svg ',
        text: 'Konnsys App Support'
    },
    {
        images: './images/homeautomation/overview/92._Retrofit_Solutions.svg ',
        text: 'Retrofit Solutions'
    },
    {
        images: './images/homeautomation/overview/93._Scene_Control.svg ',
        text: 'Scene Control'
    },
    {
        images: './images/homeautomation/overview/94._Multiple_Sensor_Integration.svg',
        text: ' Multiple Sensor Integration'
    },
    // {
    //     images: './images/homeautomation/overview/87._Temperature Sensor.svg',
    //     text: 'Predictive energy optimization'
    // },
]



// USed SOLAR AND WIND hardware offerings 
// Components = Cards.js molecule, 
export const solarhardware = [
    {
        images: './images/Micro Inverter 2 (1).png',
        head: 'MSI1500',
        // para: 'The MSI1500 is MNRE-certified smart micro inverter solutions with IEC Compliance and IP67 Rated (NEMA6) enclosure environmental rating. Our user-friendly GPRS monitoring system helps to operate and remotely change electrical parameters of the inverter hassle-free. It has portable microinverter products inclusive of 4 MPPTs .The smart-grid ready system provides an easy installation and supports ground fault detection.',
        // read: 'read more'
    },
    {
        images: './images/DKS_9053e.png',
        head: 'MS1700',
        // para: 'The MS1700 is MNRE-certified smart micro inverter solutions that comes with 2 MPPTs. The solution provides various smart features including module-wise remote monitoring and controlling. This helps owners leverage the best output out of their solar inverters right and benefit from high energy generation as compared to regular solar panel systems.',
        // read: 'read more'

    },
    {
        images: './images/1.0-solar.png',
        head: 'Universal Stick Logger',
        // para: 'The Universal Stick Logger is an all-inverter compatible device that when attached enables the user to monitor and control the parameters of that inverter. It’s a solution that caters retrofit features and enables users to smartify their existing solar infrastructure.',
        // read: 'read more'
    },
    {
        // images: './images/1.0-solar.png',
        images: './images/solar/hproducts/SunsightDataLogger.png',
        head: 'Data Logger',
        // para: 'The Universal Stick Logger is an all-inverter compatible device that when attached enables the user to monitor and control the parameters of that inverter. It’s a solution that caters retrofit features and enables users to smartify their existing solar infrastructure.',
        // read: 'read more'
    },
]

export const solarhardware1 = [
    {
        images: './images/solar/hproducts/Micro Inverter 2 (1).png',
        subtitle: 'MSI1500',
        // para: 'The MSI1500 is MNRE-certified smart micro inverter solutions with IEC Compliance and IP67 Rated (NEMA6) enclosure environmental rating. Our user-friendly GPRS monitoring system helps to operate and remotely change electrical parameters of the inverter hassle-free. It has portable microinverter products inclusive of 4 MPPTs .The smart-grid ready system provides an easy installation and supports ground fault detection.',
        link: 'https://www.getsunsights.com/micro-inverters/'
    },
    {
        images: './images/solar/hproducts/DKS_9053e.png',
        subtitle: 'MSI700',
        // para: 'The MS1700 is MNRE-certified smart micro inverter solutions that comes with 2 MPPTs. The solution provides various smart features including module-wise remote monitoring and controlling. This helps owners leverage the best output out of their solar inverters right and benefit from high energy generation as compared to regular solar panel systems.',
        link: 'https://www.getsunsights.com/micro-inverters/'

    },
    {
        images: './images/solar/hproducts/Dongle.196.png',
        subtitle: 'Universal Inverter Stick Logger',
        // para: 'The Universal Stick Logger is an all-inverter compatible device that when attached enables the user to monitor and control the parameters of that inverter. It’s a solution that caters retrofit features and enables users to smartify their existing solar infrastructure.',
        // read: 'read more'
    },
    {
        // images: './images/1.0-solar.png',
        images: './images/solar/hproducts/SunsightDataLogger.png',
        subtitle: 'Data Logger',
        // para: 'The Universal Stick Logger is an all-inverter compatible device that when attached enables the user to monitor and control the parameters of that inverter. It’s a solution that caters retrofit features and enables users to smartify their existing solar infrastructure.',
        link: 'https://www.getsunsights.com/data-logger/'
    },
]


// USed SOLAR AND WIND Software offerings 
// Components = Cards.js molecule, 
export const solarsoftware = [
    {
        images: './images/solar/SunSight_Live_2x.png',
        head: 'SunSights Live',
        productname: 'SunSights Live',
        // para: 'SunSights Live is an IoT-powered solar infrastructure monitoring dashboard. The web application helps in monitoring the technical and financial performance of one or more SunSights products. The dashboard enables easy management of the solar infrastructure by providing features like remote fault detection, troubleshooting, asset management, and much more.',
        read: 'read more'

    },
]


//Gaming page harderware offrings

export const gaminghardware = [
    {
        images: './images/iot_bg_cloud4.png',
        productname: 'Smart Targets',
        para: 'Smart Targets are IoT Targeting App controlled reactive targets powered with advanced wireless technology for a real-time practice and gaming experience.',
        para1: ' Smart targets are portable and connect with up to 20 targets. The system provides the platform for cloud and events for multi-sports experience. The system is designed to help enhance skills and provide a premise for advancement in chosen sports.',
        read: 'read'
    },
    {
        images: './images/iot_bg_just_target.png',
        productname: 'Just Targets',
        para: 'Just Targets are portable, reusable, and reactive targets for everyday practice or fun gaming experiences. ',
        para1: 'Leveraging reactive touch technology, Just Targets work without additional targets or connections and comes with 20 hours AA Battery life for non-stop experiential gaming and adventure.',
        read: 'read'
    },

]


export const gamingsoftware = [
    {
        images: './images/gaming/Gaming-Iot-targeting-App.png',
        productname: 'IoT Targeting Mobile App',
        para: 'The IoT Targeting Mobile App is an exclusively designed Android and iOS app that takes the IoT Targeting gaming experience to the next level. The application facilitates both, online and offline connection of wireless targets and provides a global platform for competitive and group gaming using cloud. The app also provides user-friendly features such as adjusting target settings, choosing gaming modes, and automatically deriving scores.',
        read: 'read'
    },
]




export const gamingapplications = [
    {
        images: './images/iot_photostrip_img_4.png',
        productname: 'Smart Gaming',
    },
    {
        images: './images/iot_photostrip_img_5.png',
        productname: 'Sports Training',
    },
    {
        images: './images/army-soldiers-fighting-with-guns-defending-their-country_1303-26687.png',
        productname: 'Military Training',
    },
]



//yet to update
export const gamingOverview = [
    {
        images: './images/gaming/33._Android and iOS App control.svg',
        text: 'Android & iOS App Control'
    },
    {
        images: './images/gaming/34._Wi-Fi Connectivity.svg',
        text: 'Wi-Fi Connectivity'
    },
    {
        images: './images/gaming/35._Cloud for online and-offline mode operations.svg',
        text: 'Cloud For Online & Offline Mode'
    }
]


export const expertise = [
    {
        icon: './images/Group 82620.svg',
        h4: 'Deep Learning',
        h6: 'Boost the performance of your forecasting, decision-making, and other operations with deep learning technology. Our deep learning based solutions are customized to help you attain business excellence and create class-apart product and service offerings.'
    },
    {
        icon: './images/AI.svg',
        h4: 'AI-Based Solutions',
        h6: 'We offer innovative and cutting-edge AI-based products and services that help solve your biggest business challenges through intelligent decision-making. Reinvent how you work at lower costs and minimize errors with our wide spectrum of solutions.'
    },
    {
        icon: './images/Layer_7.svg',
        h4: 'Natural Language Processing',
        h6: 'We have precedented expertise in providing Natural Language Processing Services leveraging our best-in-class AI tools. Our team of NLP Experts help you extract, process, analyse, and understand structured data to fetch insights that matter.'
    },
    // {
    //     icon: './images/Group 82615.svg',
    //     h4: 'Internet of Things',
    //     h6: 'Our Internet of Things expertise helps businesses set up central data repository to house real-time sensor data and leverage data science for predictive maintenance of your remote assets helping you reduce downtime and boost service quality.'
    // },
    {
        icon: './images/Group 82617.svg',
        h4: 'Machine Learning',
        h6: 'We leverage ML with a business-first outlook to build powerful and customized solutions for automation. Our offerings help you reduce human intervention thereby optimizing costs, throughput, and organizational efficiency at large.'
    },

]


export const highspeed = [
    {
        count: '01',
        uli: 'Requirements Analysis',
        firstli: 'Design and architecture specification',
        secli: '',
        thirdli: '',
        fourthli: '',
    },
    {
        count: '02',
        uli: 'Design',
        firstli: 'Electronic component engineering',
        secli: 'Power and clock design',
        thirdli: 'Enclosure modeling, CAD design',
    },
    {
        count: '03',
        uli: 'Implementation',
        firstli: 'Schematic',
        secli: 'Placement and routing',
        thirdli: 'Signal integrity'
    },
    {
        count: '04',
        uli: 'Prototyping',
    },
    {
        count: '05',
        uli: 'Translation services',
        firstli: 'Fabrication',
        secli: 'Assembly - in house',
        thirdli: 'Packaging',
        fourthli: 'Rapid prototyping for enclosures',
    },
    {
        count: '06',
        uli: 'Diagnostics',
        firstli: 'Reliability analysis',
        secli: 'Thermal analysis',
        thirdli: 'Pre and post layout signal integrity check and cross talk analysis',
        fourthli: 'EMI and EMC analysis',
    },

]



export const technologystack = [
    {
        images: './images/BlueIcons -20-01.svg',
        number: '01',
        title: 'Web Servers',
        subtitle: 'IIS, Apache, NGINX , Jetty'
    },
    {
        images: './images/BlueIcons -20-02.svg',
        number: '02',
        title: 'UI development',
        subtitle: 'Bootstrap, jQuery, Javascript, HTML5, CSS3, Angular'
    },
    {
        images: './images/BlueIcons -20-03.svg',
        number: '03',
        title: 'Server Side Languages',
        subtitle: '.NET, C#, PHP, .NET MVC, .NET Core, WPF, JAVA, Python'
    },
    {
        images: './images/BlueIcons -20-04.svg',
        number: '04',
        title: 'Databases',
        subtitle: 'PostGreSQL, MySQL, MSSQL, MongoDB, Cassandra, SQLite'
    },
    {
        images: './images/BlueIcons -20-06.svg',
        number: '05',
        title: 'Messaging',
        subtitle: 'MQTT, WS, AMQP, Nodejs, RabbitMQ'
    },
    {
        images: './images/BlueIcons -20-10.svg',
        number: '06',
        title: 'Frameworks',
        subtitle: 'Joomla, Wordpress, Spring boot, Gradle, Spring, MVC, Hibernate Build'
    },
    {
        images: './images/BlueIcons -20-08.svg',
        number: '07',
        title: 'Version management',
        subtitle: 'Jenkins, Docker, Github'
    },
    {
        images: './images/BlueIcons -20-14.svg',
        number: '08',
        title: 'OS',
        subtitle: 'Windows, Linux, CentOS'
    },
    {
        images: './images/BlueIcons -20-16.svg',
        number: '09',
        title: 'Data Exchange',
        subtitle: 'JSON, AJAX, XML, SOAP'
    },
    {
        images: './images/BlueIcons -20-02.svg',
        number: '10',
        title: 'Mobile',
        subtitle: 'Android, Flutter, iOS, React Native'
    },
]

//White icons only 4 for transformersTemplate overview
export const transformer = [
    {
        images: './images/transformers/iconsOverview/41._Oil Flow & Level Monitoring.svg',
        text: 'Oil Flow & Level Monitoring'
    },
    // {
    //     images: './images/transformers/iconsOverview/42._Fire Alerts.svg',
    //     text: 'Fire Alerts'
    // },
    {
        images: './images/transformers/iconsOverview/43._Top and Bottom Oil Temprature Management.svg',
        text: 'Oil Temperature Management',
        // text: 'Top & Bottom Oil Temperature Management'
    },
    {
        images: './images/transformers/iconsOverview/44._Fan Control & Cooling Monitoring.svg',
        text: 'Fan Control & Cooling Monitoring'
    },
    {
        images: './images/transformers/iconsOverview/45._Phase voltage, current & overload capacity measurment.svg',
        text: 'Voltage & Current Measurement',
        // text: 'Phase voltage, current & overload capacity measurement',
    },
    // {
    //     images: './images/transformers/iconsOverview/46._Data Logging.svg',
    //     text: 'Data Logging',
    // },
]

//  Used in IP CORE page @/ipcore
//  component name IPcoreTemplates >>  OurExpertiseBlue.js


export const ipcoreproducts = [
    {
        images: './images/ipcore/Products/60._Communication Cores.svg',
        url: "https://core.slscorp.com/ip-cores/communication.html",
        uli: 'Communication Cores',
        firstli: 'USB 3.2 Gen2x2 (20Gbps) Device',
        secli: 'USB 3.2 Gen 2 (10Gbps) Device',
        thirdli: 'USB 3.2 Gen 1 (5Gbps) Device',
        fourthli: 'USB 2.0 Device/HUB/Host/OTG',
        fifthli: 'USB 1.1 Device/Host',
    },
    {
        images: './images/ipcore/Products/61._Interface Cores.svg',
        url: "https://core.slscorp.com/ip-cores/interface.html",
        uli: 'Interface Cores',
        firstli: 'I2S Controller',
        secli: 'I2C Master/Slave/Controller',
        thirdli: 'SPI Slave to Avalon Master Bridge',
    },
    // {
    //     images: './images/ipcore/Products/62._Memory Controller Core.svg',
    //     url: "https://core.slscorp.com/ip-cores/memory.html",
    //     uli: 'Memory Controller Core',
    //     firstli: 'SD/eMMC Host Controller',
    // },
    {
        images: './images/ipcore/Products/63._OS and HAL Drivers to support IP Cores.svg',
        url: "https://core.slscorp.com/services/software-development-services.html",
        uli: 'OS and HAL Drivers to support IP cores',
        firstli: 'End-to-end product development of applications to support IP Core',
    },
    {
        images: './images/ipcore/Products/64._Hardware Boards.svg',
        url: "https://core.slscorp.com/products.html",
        uli: 'Hardware Boards',
        firstli: 'FMC Snap On Boards',
        secli: 'HSMC Snap On Boards',
        thirdli: 'Add On Boards',
        fourthli: 'Development Boards',
        fifthli: 'Test and Measurement',
        sixthli: 'Download Cables',
    },

]

export const expertiseblue = [
    {
        images: './images/ipcore/expertise/65._Architecture design.svg',
        text: 'Architecture design'
    },
    {
        images: './images/ipcore/expertise/66._RTL Coding (Verilog, System Verilog).svg',
        text: 'RTL coding (Verilog, System Verilog)'
    },
    {
        images: './images/ipcore/expertise/67._Functional Verification.svg',
        text: 'Functional verification'
    },
    {
        images: './images/ipcore/expertise/68._Validation on Hardware platform.svg',
        text: 'Validation on hardware platform'
    },
    {
        images: './images/ipcore/expertise/69._Circuit Design.svg',
        text: 'Circuit design'
    },
    {
        images: './images/ipcore/expertise/70._FPGA Design Tools.svg',
        text: 'FPGA Design Tools'
    },
    {
        images: './images/ipcore/expertise/71._Excellent Customer.svg',
        text: 'Excellent customer support'
    },
    {
        images: './images/ipcore/expertise/72._Serving customers since 20+ years.svg',
        text: 'Serving customers since 20+ Years'
    },
]
export const industries = [
    {
        images: './images/ipcore/Industries/77._Consumer Electronics.svg',
        text: 'Consumer Electronics'
    },
    {
        images: './images/ipcore/Industries/78._Defence.svg',
        text: 'Defence'
    },
    {
        images: './images/ipcore/Industries/79._Test & Measurment.svg',
        text: 'Test and Measurement'
    },
    {
        images: './images/ipcore/Industries/80._High Speed Camera.svg',
        text: 'High Speed Camera'
    },
    {
        images: './images/ipcore/Industries/81._Automobile.svg',
        text: 'Automobile'
    },
    {
        images: './images/ipcore/Industries/82._Data Acquisition.svg',
        text: 'Data Acquisition'
    },
    {
        images: './images/ipcore/Industries/84._Security Product.svg',
        text: 'Security Product'
    },
    {
        images: './images/ipcore/Industries/83._Medical Devices.svg',
        text: 'Medical Devices'
    },
    {
        images: './images/ipcore/Industries/85._Avionics.svg',
        text: 'Avionics'
    },
    {
        images: './images/ipcore/Industries/86._Multimedia.svg',
        text: 'Multimedia'
    },
]


export const homeproducts = [
    {
        images: './images/homeautomation/products/Smart-Bulb.png',
        subtitle: 'Smart Bulb',
        link: 'https://www.konnsys.com/Home/SmartBulb'
    },
    {
        images: './images/homeautomation/products/Hub.png',
        subtitle: 'Hub',
        link: 'https://www.konnsys.com/Home/TheHub'
    },
    {
        images: './images/homeautomation/products/Motion-Sensor.png',
        subtitle: 'Motion Sensor',
        link: 'https://www.konnsys.com/Home/TheHub#motion_sensor'
    },
    {
        images: './images/homeautomation/products/Temp-Humidity-Sensor.png',
        subtitle: 'Temp-Humidity Sensor',
        link: 'https://www.konnsys.com/Home/TheHub#temp_humidity_sensor'
    },
    {
        images: './images/homeautomation/products/Gas-Detector.png',
        subtitle: 'Gas Detector',
        link: 'https://www.konnsys.com/Home/GasDetector'
    },
    {
        images: './images/homeautomation/products/Multi-Function-Button.png',
        subtitle: 'Multi-Function Button',
        link: 'https://www.konnsys.com/Home/TheHub#multi_function_button'
    },
    {
        images: './images/homeautomation/products/Smart-Cabinet-Lock.png',
        subtitle: 'Smart Cabinet Lock',
        link: 'https://www.konnsys.com/Home/CabinetLock'
    },
    {
        images: './images/homeautomation/products/Smoke-Detector.png',
        subtitle: 'Smoke Detector',
        link: 'https://www.konnsys.com/Home/SmokeDetector'
    },
    {
        images: './images/homeautomation/products/Gang-Switch.png',
        subtitle: 'Gang Switch',
        link: 'https://www.konnsys.com/Home/GangWallSwitch'
    },
]




export const softdevservices = [
    {
        images: "./images/Layer_20-soft-dev.svg",
        title: "Web Development",
        li1: "Cloud Solutions",
        li2: "Custom Web Application",
    },
    {
        images: "./images/Layer_21-soft-dev.svg",
        title: "Application Development",
        li1: "Windows Development",
        li2: "Product Development",
    },
    {
        images: "./images/Layer_23-soft-dev.svg",
        title: "System Development",
        li1: "System Porting & Customization",
        li2: "Driver Development",
    },
    {
        images: "./images/Layer_26-soft-dev.svg",
        title: "Embedded Solutions",
        li1: "Firmware Development",
        li2: "Driver Development",
    },
    {
        images: "./images/Layer_24-soft-dev.svg",
        title: "Enterprise Solutions",
        li1: "HRM System",
        li2: "ERP System",
    },
    {
        images: "./images/Layer_25-soft-dev.svg",
        title: "Mobile App Development",
        li1: "Application Development",
        li2: "System Porting & Customization",
    },
]


export const homeapp = [
    {
        images: './images/homeautomation/living-room.png',
        text: 'Homes'
    },
    {
        images: './images/homeautomation/Industria.png',
        text: 'Industries'
    },
    {
        images: './images/homeautomation/Office.png',
        text: 'Offices'
    },
    {
        images: './images/homeautomation/Warehouse.png',
        text: 'Warehouses'
    }
]

